import React, { Component } from "react";
import FlowInput from "./../flowInput/FlowInput";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { apiPrefix, baseTitle } from "./../../helper";
import { Helmet } from "react-helmet";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "./../login/actions/authActions";
import ReactLoading from "react-loading";
import qs from "qs";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

const TITLE = `${baseTitle} Retrain`;

class Retrain extends Component<
  Props,
  {
    msgNotRecognizedByAi: any;
    flows: any[];
    selectedFlowName: string | null;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      msgNotRecognizedByAi: false,
      flows: [],
      selectedFlowName: null
    };
    this.getMsgNotRecognizedByAi();
    this.getFlows();
  }

  getFlows = async () => {
    try {
      let url = apiPrefix + "automation";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      if (response.data.success) {
        this.setState({
          flows: response.data.data.flows
        });
      }
    } catch (error) {
      console.log(error);
      // this.props.logoutUser();
    }
  };

  getMsgNotRecognizedByAi = async () => {
    try {
      let url = apiPrefix + "not-recognized";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });

      if (response.data.success) {
        let msgNotRecognizedByAi = response.data.data.messages;
        this.setState({ msgNotRecognizedByAi });
        console.log("Msg Not Recognized By Ai: ", response.data.data.messages);
      }
    } catch (error) {
      console.log("User activity error: ", error);
    }
  };

  addWit = async (inputData, id, text) => {
    try {
      if (inputData) {
        let url = `${apiPrefix}wit/retrain`;
        let headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`
        };
        let data = {
          intent: inputData
            .replace(/\s+/g, "_")
            .toUpperCase(),
          text,
          id
        };
        let response = await axios.put(url, qs.stringify(data), {
          headers: headers
        });
        if (response.data.data.status.sent) {
          toast.success("Trained suucessfully")
        }
        console.log(response);
      }
    } catch (error) {
      console.log("Errror from wit", error);
      toast.error("Something wrong !")
    }
  };


  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <div className="row m-0" style={{ paddingTop: 60 }}>
            <div className="col-md-12 mt_20">
              <div className="user-table user_main_table card">
                <div className="user_table_header_flow retrain_header">
                  <h4>Train "Not Recognized" User Query:</h4>
                </div>
                <div className="flow_body">
                  <div className="flow_header D_flex">
                    <img src="assets/retrain_header_icon.png" />
                    <p>User Query</p>
                  </div>
                  <div className="flow_scroll ">
                    { this.state.flows.length > 0 && this.state.msgNotRecognizedByAi &&
                      this.state.msgNotRecognizedByAi.length > 0 &&
                      this.state.msgNotRecognizedByAi.map(
                        (item: any, index: number) => {
                          return (
                            <div
                              className="flow_list d-flex align-items-center table_padding"
                              key={index}>
                              <span>{item.message}</span>
                              <FlowInput
                                inputStyle={JSON.stringify({ height: 30 })}
                                flows= {this.state.flows}
                                addWit={this.addWit}
                                id={item.id}
                                text={item.message}
                              />
                            </div>
                          );
                        }
                      )}

                    <div className="d-flex justify-content-center">
                      {!this.state.msgNotRecognizedByAi && (
                        <ReactLoading
                          type={"bars"}
                          color={"#01a0fb"}
                          height={"10%"}
                          width={"10%"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(Retrain);
